<script setup lang="ts">
import { BigProps } from "./Big.props"
import { carouselOptions } from "./Big.carouselOptions"

withDefaults(defineProps<BigProps>(), {
  theme: "tiffany",
  ImageLast: false
})

const { width } = useWindowSize()
</script>

<template>
  <section
    class="grid-standard py-6 md:gap-6 md:pb-8 md:pt-10"
    :class="{
      'bg-white': theme === 'white',
      'bg-gradient-to-r from-green-main to-[#40BACA] ': theme === 'green',
      'bg-tiffany-10 ': theme === 'tiffany',
      'bg-gradient-to-r from-yellow-main to-yellow-30': theme === 'orange'
    }"
  >
    <div
      class="col-span-full md:col-span-3"
      :class="{ 'md:order-last	': isImageLast }"
    >
      <AppLink
        class="h-full w-full overflow-hidden rounded"
        :to="cta ? cta.path : ''"
      >
        <UtilsWrapperImage
          v-if="brandImage"
          v-bind="brandImage"
          class="wrapper-image-full-container-centered"
        />
      </AppLink>
    </div>

    <div class="col-span-full md:col-span-9">
      <div class="my-4 flex items-end justify-between md:mt-0">
        <div class="flex flex-col gap-1">
          <h2
            v-if="brandTitle"
            class="mouse-bold uppercase"
            :class="[theme === 'green' ? 'text-white' : 'text-black-80']"
          >
            {{ brandTitle }}
          </h2>

          <p
            v-if="title"
            class="mammoth-bold"
            :class="{
              'text-black-main': theme === 'orange',
              'text-white': theme === 'green'
            }"
          >
            {{ title }}
          </p>
        </div>

        <UtilsButton
          v-if="cta"
          :="cta"
          :theme="
            theme === 'green'
              ? 'outlined-white'
              : theme === 'orange'
              ? 'outlined-black'
              : 'outlined-green'
          "
          class="hidden cursor-pointer self-end md:block"
          :class="{ 'text-black-main': theme === 'orange' }"
          order="last"
          type="button"
        >
          <template #icon>
            <IconsArrowRight class="h-5 w-5" />
          </template>
        </UtilsButton>
      </div>

      <div
        class="
          carousel-container-overflowed
          -mx-4
          -mt-4
          overflow-hidden
          p-4
          md:-mb-6
          md:pb-6
        "
        :class="[
          isImageLast
            ? 'md:ml-[-71px] md:mr-0 md:pl-[71px] md:pr-0 lg:ml-[-228px] lg:pl-[228px]'
            : 'md:ml-0 md:mr-[-71px] md:pl-0 md:pr-[71px] lg:mr-[-228px] lg:pr-[228px]'
        ]"
      >
        <GAListCarouselBrandBanner :name="brandTitle">
          <GAItem :id="brandTitle" :item="cards">
            <GAViewItemList>
              <AppCarousel
                v-if="cards"
                class="carousel-overflowed select-none"
                :items="cards"
                :swiper-props="carouselOptions"
                :key="width"
                :theme="carouselOptions.theme"
              >
                <template #default="{ item }">
                  <ProductTileOverallVertical v-bind="item" />
                </template>
              </AppCarousel>
            </GAViewItemList>
          </GAItem>
        </GAListCarouselBrandBanner>
      </div>

      <UtilsButton
        :="cta"
        type="button"
        :theme="
          theme === 'green'
            ? 'outlined-white'
            : theme === 'orange'
            ? 'outlined-black'
            : 'outlined-green'
        "
        class="w-full cursor-pointer md:hidden"
        order="last"
      >
        <template #icon>
          <IconsArrowRight class="h-5 w-5" />
        </template>
      </UtilsButton>
    </div>
  </section>
</template>
